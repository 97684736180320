<template lang='pug'>
.basket(v-if="basket && user")
  .basket__cont.cont
    form.basket__form(v-if="basket.products.length", @submit.prevent="sendBasket")
      the-basket-products(:start-products="basket.products", @change-products="setProducts")
      ol.basket__steps
        the-basket-delivery(@change-delivery="setDeliveryId")
        the-basket-info(
            @change-name="setName",
            @change-email="setEmail",
            @change-phone="setPhone"
          )
        //the-basket-payment(@change-payment="setPaymentId")
      .basket__button-box
        button.basket__submit.button-white(type="submit") {{buttonText}}
        span.basket__small-text(v-if="!user.is_auth") и принять условия&ensp;
          router-link.basket__policy-link(:to="{ name: 'Policy' }", target="_blank") Политики конфиденциальности
    template(v-else)
      p.basket__text.text-big
        | В корзине пока ничего нет. Перейдите на&thinsp;
        router-link.basket__home-link(:to="{ name: 'Home' }") главную страницу
        | &thinsp;или воспользуйтесь поиском, если ищете что-то конкретное
      router-link.basket__link.link-more(:to="{ name: 'Home' }") На главную
</template>

<script>
import '@/assets/styles/components/basket.sass'
import TheBasketProducts from './TheBasketProducts'
import TheBasketDelivery from './TheBasketDelivery'
import TheBasketInfo from './TheBasketInfo'
import { basketCheckout, getUser } from '../../assets/scripts/API'
import Storage from '../../assets/scripts/Storage'

export default {
  name: 'TheBasket',
  props: { basketStart: Object },
  components: { TheBasketProducts, TheBasketDelivery, TheBasketInfo },
  data () {
    return {
      basket: null,
      user: null,
      products: [],
      name: null,
      email: null,
      phone: null,
      deliveryId: 1,
      paymentId: 1
    }
  },
  computed: {
    buttonText () {
      return this.paymentId === 1 ? 'Оформить заказ' : 'Оплатить'
    }
  },
  watch: {
    basketStart (data) {
      this.basket = data
    }
  },
  methods: {
    setProducts (products) {
      this.basket.products = products
      this.products = products
    },
    setName (name) {
      this.name = name
    },
    setEmail (email) {
      this.email = email
    },
    setPhone (phone) {
      this.phone = phone
    },
    setDeliveryId (deliveryId) {
      this.deliveryId = deliveryId
    },
    async sendBasket () {
      const res = await basketCheckout(this.products, this.deliveryId, this.name, this.email, this.phone)
      if (res.status !== 'success') return this.$router.go(0)
      Storage.showThanks = true
      await this.$router.push({ name: 'Thanks' })
    }
  },
  async mounted () {
    this.user = await getUser()
  }
}
</script>
