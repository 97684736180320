<template lang="pug">
head-meta(title-page="Корзина")
user-menu(block-name="Корзина")
the-basket(:basket-start="basket")
contacts-line
</template>

<script>
import TheBasket from '../components/basket/TheBasket'
import UserMenu from '../components/_user/UserMenu'
import { getBasket } from '../assets/scripts/API'
import HeadMeta from '../components/_layout/HeadMeta'
import ContactsLine from '../components/_layout/TheContactsLine'

export default {
  components: { ContactsLine, HeadMeta, UserMenu, TheBasket },
  name: 'BasketPage',
  data () {
    return {
      basket: null
    }
  },
  async beforeRouteEnter (to, from, next) {
    const basket = await getBasket()
    next(vm => { vm.basket = basket })
  }
}
</script>
