<template lang="pug">
.basket__step
  h2.basket__title.title Доставка
  .basket__area
    p.basket__text-big.text-big Стоимость доставки рассчитает и сообщит менеджер. Бесплатная доставка по России от {{ minPriceForFreeDelivery }} ₽
    label.basket__radio(v-for="item in deliveryList", :key="item.id")
      input.basket__radio-input(type="radio", v-model="delivery" :value="item.id", @change="eventDeliveryChange")
      span.basket__radio-name.text {{ item.value }}
      span.basket__radio-sign(v-if="item.sign") {{ item.sign }}
</template>

<script>
import { minPriceForFreeDelivery } from '../../assets/scripts/variables'

export default {
  name: 'TheBasketDelivery',
  emits: ['change-delivery'],
  data () {
    return {
      delivery: 1,
      deliveryList: [
        { id: 1, value: 'Самовывоз (Выборг, ул. Штурма, 1А)' },
        { id: 2, value: 'Почта России' },
        { id: 3, value: 'СДЭК' },
        { id: 4, value: 'Обсудить с менеджером' }
      ],
      minPriceForFreeDelivery
    }
  },
  methods: {
    eventDeliveryChange () {
      this.$emit('change-delivery', this.delivery)
    }
  }
}
</script>
